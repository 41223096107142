<template>
  <span
    class="ui-icon ui-noselect"
    :class="classes"
    :style="styles"
    @click="$emit('click', $event)"
  >
    <component
      v-if="provider"
      :is="provider.component"
      :value="provider.value"
      :fullValue="filteredValue"
      :size="size"
      :light="light"
      :inactive="inactive"
      :color="color"
    ></component>
    <span
      v-else
      :title="filteredValue"
    >�</span>

    <div
      v-if="$slots.default"
      class="contents-slot"
    >
      <slot></slot>
    </div>
  </span>
</template>

<script>
import AliasMime from './Alias/Mime.js';

const ProviderImage = () => import('./Provider/Image.vue');
const ProviderGoogle = () => import('./Provider/Google.vue');
const ProviderMdi = () => import('./Provider/Mdi.vue');
const providers = {
  http: ProviderImage,
  https: ProviderImage,
  data: ProviderImage,
  g: ProviderGoogle,
  mdi: ProviderMdi
};

export default {
  name: 'ui-icon',

  props: {
    value: {
      type: String,
      required: false,
      default: '�'
    },

    size: {
      type: String,
      required: false,
      default: null
    },

    color: {
      type: String,
      required: false,
      default: null
    },

    light: {
      type: Boolean,
      required: false,
      default: false
    },

    inactive: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    filteredValue() {
      if (this.value && this.value.substring(0, 5) == 'mime:') {
        return AliasMime(this.value.substring(5));
      }
      return this.value;
    },

    classes() {
      return [
        this.light ? '--light' : '--dark',
        this.inactive ? '--inactive' : null
      ];
    },

    styles() {
      return {
        color: this.color || undefined,
        fontSize: this.size ? this.size + 'px' : 'var(--ui-icon-size)'
      };
    },

    provider() {
      if (
        !this.filteredValue ||
        typeof this.filteredValue.split == 'undefined'
      ) {
        return null;
      }

      let parts = this.filteredValue.split(':', 2);
      if (typeof providers[parts[0]] == 'undefined') {
        return null;
      }

      return {
        key: parts[0],
        component: providers[parts[0]],
        value: parts[1]
      };
    },

    cssColor() {
      return this.reactiveEl
        ? window.getComputedStyle(this.reactiveEl).color
        : null;
    }
  },

  data() {
    return {
      reactiveEl: null
    };
  },

  mounted() {
    this.reactiveEl = this.$el;
  }
};
</script>

<style lang="scss">
.ui-icon {
  --ui-icon-size: 24px; // material icon's default size

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.--light {
    color: rgba(255, 255, 255, 1);
  }

  &.--light.--inactive {
    opacity: 0.7;
  }

  &.--dark.--inactive {
    opacity: 0.3;
  }

  .contents-slot {
    padding-left: 4px;
    font-size: auto;
    white-space: nowrap;
    color: inherit;
  }
}
</style>